import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/StoreList.css';
import { api } from '../utils/apiHelper';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Importing a map pin icon

const StoresList = () => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await api('/stores');
        console.log('Fetched stores:', response.stores); // Log the fetched stores
        setStores(response.stores); // Ensure the stores are set correctly from the response data
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  const formatAddress = (location) => {
    if (!location) return 'No location available';
    
    const { streetAddress, city, state, zipCode, country, neighborhood, landmark } = location;
    let formattedAddress = `${streetAddress}, ${city}, ${state} ${zipCode}, ${country}`;
    
    if (neighborhood) {
      formattedAddress += `, Neighborhood: ${neighborhood}`;
    }
    
    if (landmark) {
      formattedAddress += `, Landmark: ${landmark}`;
    }

    const query = encodeURIComponent(`${streetAddress}, ${city}, ${state} ${zipCode}, ${country}`);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;

    return (
      <span
        className="maps-link"
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering store click when clicking on the address
          window.open(googleMapsUrl, '_blank');
        }}
        style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
      >
        <FaMapMarkerAlt style={{ marginRight: '5px', color: 'red' }} />
        {formattedAddress}
      </span>
    );
  };

  const handleStoreClick = (storeId) => {
    console.log('Navigating to store with ID:', storeId); // Log the storeId to ensure it's correct
    navigate(`/stores/${storeId}`);
  };

  return (
    <div className="stores-list-container">
      <h1 className="stores-list-header">All Stores</h1>
      {stores.length > 0 ? (
        stores.map((store) => (
          <div key={store._id} className="store-card" onClick={() => handleStoreClick(store.storeId)}>
            <h2 className="store-title">{store.name}</h2>
            <p className="store-description">Description: {store.description}</p>
            <p className="store-location">
              Location: {store.location && formatAddress(store.location)}
            </p>
            <p className="store-owner">Owner: {store.userName} ({store.userEmail})</p>
          </div>
        ))
      ) : (
        <p className="no-stores">No stores found</p>
      )}
    </div>
  );
};

export default StoresList;
